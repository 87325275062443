import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundPageComponent} from './errors/not-found-page/not-found-page.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/login'
  },
  {
    loadChildren: () => import('./login-page/login-page.module').then(m => m.LoginPageModule),
    path: 'login'
  },
  {
    loadChildren: () => import('./admin-layout/admin-layout.module').then(m => m.AdminLayoutModule),
    path: ''
  },
  {
    loadChildren: () => import('./guest-layout/guest-layout.module').then(m => m.GuestLayoutModule),
    path: 'menu'
  },
  {
    component: NotFoundPageComponent,
    path: '**'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
