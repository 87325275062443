import {DxButtonModule, DxDataGridModule, DxListModule, DxLoadPanelModule} from 'devextreme-angular';
import {CommonModule} from '@angular/common';
import {DevTableComponent} from './dev-table.component';
import {MaterialModule} from 'src/app/shared/material.module';
import {NgModule} from '@angular/core';

@NgModule({
  declarations: [DevTableComponent],
  exports: [DevTableComponent],
    imports: [
        CommonModule,
        MaterialModule,
        DxDataGridModule,
        DxListModule,
        DxLoadPanelModule,
        DxButtonModule
    ]
})

export class DevTableModule {
}
