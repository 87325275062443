import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  constructor(private httpClient: HttpClient) {
  }

  public deleteRequest(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.delete(url).subscribe((response) => {
        resolve(response);
      });
    }).catch((error) => {
      throw(new Error(error));
    });
  }

  public getRequest(url: string, additionalHeaders?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(url, additionalHeaders).subscribe((response) => {
        resolve(response);
      });
    }).catch((error) => {
      throw(new Error(error));
    });
  }

  public patchRequest(object: any, url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.patch(url, object).subscribe((response) => {
        resolve(response);
      });
    }).catch((error) => {
      throw(new Error(error));
    });
  }


  public postRequest(object: any, url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.post(url, object).subscribe((response) => {
        resolve(response);
      });
    }).catch((error) => {
      throw(new Error(error));
    });
  }

  public putRequest(object: any, url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.put(url, object).subscribe((response) => {
        resolve(response);
      });
    }).catch((error) => {
      throw(new Error(error));
    });
  }
}
