import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {AppErrorHandler} from './errors/app-error-handler';
import {AuthorizationService} from './interceptors/authorization.service';
import {ErrorDialogComponent} from './errors/error-dialog/error-dialog.component';
import {LoadingComponent} from './shared/components/loading/loading.component';
import {MaterialModule} from './shared/material.module';
import {NotFoundPageComponent} from './errors/not-found-page/not-found-page.component';
import {DevTableModule} from "./admin-layout/components/dev-table/dev-table.module";

@NgModule({
  declarations: [
    AppComponent,
    ErrorDialogComponent,
    LoadingComponent,
    NotFoundPageComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    DevTableModule,
  ],
  providers: [
    {
      provide: ErrorHandler, useClass: AppErrorHandler
    },
    {
      provide: LocationStrategy, useClass: HashLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: AuthorizationService, multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
