import * as Sentry from '@sentry/browser';
import {SharedService} from "../shared/services/shared.service";
import {ErrorDialogComponent} from './error-dialog/error-dialog.component';
import {ErrorHandler, Injectable, NgZone} from '@angular/core';
import {LoadingService} from '../shared/services/loading.service';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '../../environments/environment';

Sentry.init({
  dsn: ''
});

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  constructor(private loadingService: LoadingService,
              private matDialog: MatDialog,
              private ngZone: NgZone,
              private sharedService: SharedService) {
  }

  handleError(error) {
    if (environment.production) {
      Sentry.captureException(error.originalError || error);
    } else {
      console.log(error)
    }
    this.loadingService.hide();
    if (error.status === 440 || error.status === 439) {
      this.sharedService.logout();
      this.ngZone.run(() => {
        this.matDialog.open(ErrorDialogComponent, {
          data: {
            message: error.status === 440 ? 'Η σύνδεση έληξε. Παρακαλώ ξανά συνδεθείτε στην εφαρμογή για να έχετε πρόσβαση.' : 'Δεν έχετε πρόσβαση στην εφαρμογή.',
            status: error.status,
            title: 'Ειδοποίηση Συστήματος'
          }
        });
      });
    } else {
      let message: string ;
      if (error.status === 499) {
        message = error.error.message;
      } else if (error.status === 415) {
        message = 'Το αρχείο που θέλετε να ανεβάσετε παρουσιάζει κάποιο πρόβλημα. Παρακαλώ βεβαιωθείτε ότι μπορείτε να το ανοίξετε και να δείτε το περιεχόμενο του.'
      } else if (error.status === 428 ){
        message = 'Παρακαλώ ανανεώστε την σελίδα.'
      } else {
        message = 'Παρουσιάστηκε κάποιο σφάλμα κατά την εκτέλεση κάποιας διεργασίας του συστήματος.'
      }
      this.ngZone.run(() => {
        this.matDialog.open(ErrorDialogComponent, {
          data: {
            message,
            status: error.status,
            title: 'Ειδοποίηση Συστήματος'
          }
        });
      });
    }
  }
}
