import {Component, Inject, OnInit} from '@angular/core';
import {LoadingService} from '../../shared/services/loading.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  templateUrl: './error-dialog.component.html'
})
export class ErrorDialogComponent implements OnInit {
  public message: string;

  constructor(private loadingService: LoadingService,
              private matDialogRef: MatDialogRef<ErrorDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    switch (this.data.status) {
      case 401:
        this.message = 'Δεν έχετε δικαίωμα εκτέλεσης αυτής της ενέργειας.';

        break;
      case 500:
        this.message = 'Παρουσιάστηκε σφάλμα κατά την εκτέλεση της ενέργειας.';

        break;
      default:
        this.message = this.data.message ? this.data.message : 'Παρουσιάστηκε σφάλμα κατά την εκτέλεση της ενέργειας.';

        break;
    }

    this.loadingService.hide();
  }

  public ngOnInit(): void {
    this.matDialogRef.updateSize('600px');

    this.matDialogRef.disableClose = true;
  }

  public closeDialog(): void {
    this.matDialogRef.close();

    // window.location.reload();
  }
}
