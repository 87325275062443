import {CurrencyPipe} from '@angular/common';
import {HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {CookieService} from "ngx-cookie-service";
import {environment} from "../../../environments/environment";
import {LoadingService} from "./loading.service";

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private headers: any;

  constructor(private router: Router,
              private cookieService: CookieService,
              private loadingService: LoadingService) {
    this.headers = new HttpHeaders();
  }

  public formatBoolean(value: number): string {
    return value !== null ? (value === 1 ? 'Ναι' : 'Όχι') : 'Όχι';
  }

  public formatCurrency(value: number): string {
    return value !== null ? (new CurrencyPipe('en-US'))
      .transform(value, 'EUR', 'symbol', '.2-2') : '-';
  }

  public formatDate(value: any): string {
    return value !== null ? moment(value).format('DD/MM/YYYY') : '-';
  }

  public formatDateTime(value: any): string {
    return value !== null ? moment(value).utc().format('DD/MM/YYYY HH:mm:ss') : '-';
  }

  public formatText(value: number | string): any {
    return value !== null && value !== '' ? value : '-';
  }

   public async logout(): Promise<void> {
    this.loadingService.show();
    this.cookieService.delete('Token');
    sessionStorage.clear();
    localStorage.clear();
    this.loadingService.hide();
    this.router.navigate(['login'])
  }
}
