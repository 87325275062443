import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {BehaviorSubject, lastValueFrom} from 'rxjs';
import DataSource from 'devextreme/data/data_source';
import {DatabaseService} from 'src/app/shared/services/database.service';
import {DxDataGridComponent} from 'devextreme-angular';
import {HttpClient} from '@angular/common/http';
import {LoadingService} from 'src/app/shared/services/loading.service';
import {TableConfig} from '../../models/table-config';
import {environment} from 'src/environments/environment';
import CustomStore from "devextreme/data/custom_store";
import {Router} from "@angular/router";
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { ExportingEvent } from 'devextreme/ui/data_grid';

@Component({
  selector: 'app-dev-table',
  styleUrls: ['./dev-table.component.scss'],
  templateUrl: './dev-table.component.html'
})

export class DevTableComponent implements AfterContentInit, OnChanges, OnInit {
  @Input() configuration: TableConfig;
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  // @Input() additionalColumns;
  // @Input() companyId;
  // @ViewChild('table', {static: false}) dataGrid!: DxDataGridComponent;
  // @Output() dataChange: EventEmitter<any[]> = new EventEmitter();

  public pageSize: number = 25;
  public pageIndex: number = 0;
  public dataSource: any;
  public filterData = [];
  public currentFilter;
  public filter;
  public displayMode = 'full';
  public showPageSizeSelector = true;
  public showInfo = true;
  public showNavButtons = true;
  public totalCount = 0;
  public skip = 0;
  public selectedColumns;

  readonly allowedPageSizes = [25, 50, 100, 250, 500];
  isLoading;
  customLoading: BehaviorSubject<boolean>;

  constructor(private databaseService: DatabaseService,
              private loadingService: LoadingService,
              private http: HttpClient,
              private router: Router) {
    this.skip = this.pageIndex * this.pageSize;
    this.customLoading = new BehaviorSubject<boolean>(true);
  }

  public async ngOnInit(): Promise<void> {
    // this.loadingService.show();
    this.dataSource = new CustomStore({
      key: this.configuration.key,
      loadMode: 'raw',
      load: () => {
        return lastValueFrom(this.http.get(this.configuration.model))
          .catch(() => {
            throw 'Data loading error'
          });
      },
      insert: async (values) => {
          return lastValueFrom(this.http.post(this.configuration.postModel, values))
            .catch(() => {throw 'Insertion Failed'});
      },
      update: async (key, values) => {
          return lastValueFrom(this.http.put(this.configuration.putModel + '/' + key, values))
            .catch(() => {throw 'Update failed'});
      },
      remove: async (key): Promise<any> => {
          return lastValueFrom(this.http.delete(this.configuration.deleteModel + '/' + key))
            .catch(() => { throw 'Deletion failed' });
      },
    })
    // this.data = this.initializeData();
    // this.selectedColumns = this.configuration.columns.map(c => c.name).join(',');
    // this.filter = this.configuration.filter;
    // this.initializeData();
    // this.initializeFilters();
    this.loadingService.hide();
  }

  public async refreshData () {
    await this.dataGrid.instance.refresh();
  }

  public async details(row) {
    if (this.configuration.navigateKey !== undefined) {
      await this.router.navigate([this.configuration.navigateRoute, row.data[this.configuration.navigateKey]])
    }
  }

  public customValidation(e) {
    return e.value !== undefined; // Invalid if the value is undefined
  }

  public async initializeData(): Promise<void> {
    this.dataSource = new DataSource({
      key: this.configuration.key,
      load: () => {
        return this.http.get(this.configuration.model).toPromise().then(data => data);
      },
      loadMode: 'raw'
    })
    // this.data = new DataSource({
    //   key: this.configuration.key,
    //   load: (loadOptions) => {
    //     this.updateLoadingStatus(true);
    //     const options: any = loadOptions;
    //
    //     return this.http.post(this.initializeCallURL(), options).toPromise().then((data: any) => {
    //       this.updateLoadingStatus(false);
    //       return {
    //         data: data.value,
    //         totalCount: data.total
    //       };
    //     });
    //   }
    // })
  }

  public async initializeFilters(): Promise<void> {
    for (const column of this.configuration.columns) {
      if (column.select && column.select.model) {
        let res = await this.databaseService.getRequest(column.select.model);
        this.filterData[column.name] = res.map((element) => {
          return {
            text: element[column.select.name],
            value: element[column.select.name]
          }
        })
      }
    }
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<any> {

  }

  public ngAfterContentInit(): void {
  }

  public async applyFilter(): Promise<any> {
    try {
      this.initializeData()
    } catch (error) {
      throw error;
    }
  }

  public changePageSize(): void {
  }

  private initializeCallURL(): string {
    return this.configuration.model;
  }

  public getFilterData(column) {
    return this.filterData[column.name];
  }

  public renderNonDateColumns(): any[] {
    return this.configuration.columns.filter(column => column.type !== 'date');
  }

  public renderDateColumns(): any[] {
    return this.configuration.columns.filter(column => column.type === 'date');
  }

  public updateLoadingStatus(status: boolean | null) {
    this.isLoading = status;
    this.customLoading.next(status);
  }

  deleteRow() {
    this.dataGrid.instance.deleteRow(1);

  }

  // updateRow(options: any) {
  //   options.newData = {...options.oldData, ...options.newData};
  // }

  onExporting(e: ExportingEvent) {
    const workbook = new Workbook();    
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function(options) {
            options.excelCell.font = { name: 'Arial', size: 12 };
            options.excelCell.alignment = { horizontal: 'left' };
        } 
    }).then(function() {
        workbook.xlsx.writeBuffer()
            .then(function(buffer: BlobPart) {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
            });
    });
  }
}
