<dx-data-grid id="dataGrid" [dataSource]="dataSource" (onRowClick)="details($event)"  (onExporting)="onExporting($event)">
  <dxo-export [enabled]="true" [formats]="['xlsx']"></dxo-export>
  <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
  <dxo-grouping [contextMenuEnabled]="true"></dxo-grouping>

  <dxo-editing
    mode="form"
    [allowUpdating]="!!configuration.putModel"
    [allowAdding]="!!configuration.postModel"
  >
  </dxo-editing>
  <dxi-column *ngFor="let column of configuration.columns; index as index"
              [dataField]="column.name"
              [caption]="column.title"
              [dataType]="column.type"
              [allowHeaderFiltering]="column.dataSource === true"
              [allowFiltering]="column.dataSource !== true"
              [allowExporting]="true">
    <dxo-header-filter *ngIf="column.select" [dataSource]="getFilterData(column)"></dxo-header-filter>
    <dxi-validation-rule type="custom" [validationCallback]="customValidation"></dxi-validation-rule>
  </dxi-column>
</dx-data-grid>
